// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// The warn palette is optional (defaults to red).

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

// If you want to override variables do it here
// @import "~@ng-select/ng-select/themes/material.theme.css";

@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";

// regular style toast
@import "ngx-toastr/toastr";

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
@import "ngx-toastr/toastr-bs4-alert";

// if you'd like to use it without importing all of bootstrap it requires
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "ngx-toastr/toastr-bs4-alert";

.sidebar .nav {
  width: 225px;
}

.sidebar .sidebar-nav {
  width: 225px;
}

.jobs-sec .card-body {
  text-align: center;
}

.job-in-icon {
  display: inline-block;
  width: 30px;
  vertical-align: top;
  font-size: 32px;
  line-height: 1.5;
}

.job-in-box {
  display: inline-block;
}

.del-box p {
  float: left;
  width: 95px;
  margin-bottom: 0px;
}

.del-box h3 {
  font-size: 14px;
  font-weight: normal;
  width: calc(100% - 100px);
}

.del-box {
  display: inline-block;
  width: 100%;
}

.ac-job {
  font-size: 19px;
  color: #000;
  margin-top: 6px;
  cursor: pointer;
}

.ac-job :hover {
  text-decoration: underline;
}

.jobs-list {
  padding: 10px 0px;
}

.del-box span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.jobs-list {
  margin-bottom: 1px solid #ccc;
}

.jobs-list {
  border-bottom: 1px solid #ccc;
  display: inline-block;
  width: 100%;
}

.jobs-main {
  display: inline-block;
  width: 100%;
}

.tab-content {
  margin-top: -1px;
  background: #fff;
  border: none;
}

.tab-content .tab-pane {
  padding: 0px 0px;
}

.nav-tabs {
  border-bottom: none;
  display: inline-block;
  width: 100%;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
  display: inline-block;
}

.pro-img {
  width: 50px;
}

.job-dtl-header {
  display: inline-block;
  width: 100%;
}

.job-dtl-header h3 {
  margin-bottom: 3px;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 700;
}

.map {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.dtl-user-sec {
  padding: 10px;
  display: inline-block;
  width: 100%;
}

.dtl-user-nm {
  width: calc(100% - 60px);
  margin-left: 8px;
}

.dtl-user-nm h2 {
  font-size: 18px;
  margin-bottom: 0px;
}

.dtl-user-nm p span {
  vertical-align: text-bottom;
}

.pickup-box {
  margin-top: 25px;
  padding: 0px;
}

.pickup-box h3 {
  font-size: 15px;
  color: #a6a6a6;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.pickup-box span {
  height: 12px;
  width: 12px;
  display: inline-block;
  border-radius: 50%;
}

.trip-img ul {
  margin: 0px;
  padding: 0px;
}

.trip-img ul li {
  list-style: none;
  float: left;
}

.trip-img ul li {
  list-style: none;
  float: left;
  width: 98px;
  margin-right: 11px;
  margin-top: 13px;
}

.your-trip {
  padding-top: 15px;
}

.your-trip-main {
  display: inline-block;
  width: 100%;
}

.note {
  display: inline-block;
  width: 100%;
}

.jobs-detail h2 {
  font-size: 16px;
}

.price-list {
  padding: 0px 0px 15px;
}

.all_transition {
  background: #3aaf57;
  padding: 30px 10px;
  text-align: center;
}

ul.chec-radio {
  margin: 0px;
  padding: 0px;
}

ul.chec-radio li.pz {
  display: inline;
  margin-right: 55px;
}

.chec-radio .radio-inline .clab {
  cursor: pointer;
  padding: 7px 20px;
  text-align: center;
  text-transform: uppercase;
  color: #333;
  position: relative;
  height: 34px;
  float: left;
  margin: 0;
  margin-bottom: 5px;
}

.chec-radio label.radio-inline input[type="checkbox"]:checked + div:before {
  margin-right: 5px;
  font-family: "Glyphicons Halflings";
}

.chec-radio label.radio-inline input[type="radio"] {
  display: none;
}

.chec-radio label.radio-inline input[type="radio"]:checked + div {
  color: #3aaf57;
  background-color: #fff;
  font-weight: 600;
}

.chec-radio label.radio-inline input[type="radio"]:checked + div:before {
  margin-right: 5px;
  font-family: "Glyphicons Halflings";
}

.create-jobs button {
  width: 100%;
  padding: 8px 43px;
  background: #4dbd74;
  color: #fff;
  min-width: 280px;
}

.clab span {
  display: inline-block;
  width: 100%;
}

.active-job {
  padding-bottom: 15px;
  -webkit-box-shadow: 0px 5px 7px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 7px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 7px -3px rgba(0, 0, 0, 0.1);
}

.modal-header span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #000000;
}

.add-pharmacy .form-control {
  min-height: 37px;
  background: #fff;
  border: 1px solid #ebebeb;
  /* color: #ffffff; */
  font-size: 14px;
  color: #000000 !important;
}

::placeholder {
  font-size: 12px !important;
  color: #afabab !important;
}

.add-pharmacy input {
  min-height: 37px;
  background: #fff;
  border: 1px solid #ebebeb;
  /* color: #ffffff; */
  font-size: 14px;
  color: #000000 !important;
}

.destination {
  width: 90%;
}

.destination .form-group {
  position: relative;
}

.destination span {
  position: absolute;
  top: 12px;
  left: 10px;
  font-weight: 600;
}

.destination .form-control {
  padding-left: 58px;
}

.c-info {
  display: inline-block;
  width: 100%;
}

.c-info p {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  margin-top: 6px;
}

div.radio-box {
  margin-top: 10px;
  align-items: center;
  justify-content: space-around;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  width: 24%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

/* - - - radio 01 - - - */
input[type="radio"] + label {
  transition: all 200ms cubic-bezier(0.4, 0.25, 0.3, 1);
  padding: 4px 0;
  color: #000000;
  background-color: transparent;
  border: 2px solid #fff;

  &:hover {
    opacity: 0.65;
  }

  border: 1px solid #ccc;
  font-weight: 500;
}

input[type="radio"] + label:active {
  transition: none;
  transform: scale(0.925);
}

.modal-lg {
  max-width: 950px;
}

.d-type {
  display: inline-block;
  width: 100%;
}

.d-type input[type="radio"] + label {
  position: relative;
  text-align: left;
  border: 0px;
}

.u-deliv {
  text-align: left;
}

.c-price {
  padding-top: 5px;
  font-size: 20px;
}

.u-deliv h2 {
  font-size: 15px;
}

.u-deliv p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  color: #2a2f3b;
}

.d-type input[type="radio"] + label span {
  width: 75px;
  float: left;
}

.est-pric {
  margin-bottom: 24px;
  margin-top: 24px;
  font-weight: 500;
  background-color: #fafbfd;
  padding: 10px 13px 10px 13px;
  width: 100%;
  border: 1px solid #edf1f6;
  border-radius: 5px;
}

.est-pric span {
  float: right;
  color: #2d2d2d;
}

.req-cab {
  display: block;
  background: #46bf88;
  text-transform: capitalize;
  padding: 6px 10px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin: 0px auto;
  cursor: pointer;
  border: 2px solid #46bf88;
  border-radius: 5px;
  width: 165px;
  font-size: 13px;
}

.create-jobs {
  border-radius: 0px;
}

.create-map {
  padding: 0px;
}

.find-cab {
  position: relative;
}

.cab-icon {
  width: 72px;
  position: absolute;
  top: -34px;
  left: 0;
  right: 0;
  display: block;
  margin: 0px auto;
}

.find-cab h2 {
  font-size: 26px;
  color: #2d2d2d;
  text-align: center;
  margin-top: 75px;
  font-weight: 600;
}

.find-cab p {
  text-align: center;
  padding-top: 17px;
  width: 350px;
  margin: 0px auto;
}

.jobs-det {
  padding: 35px 10px;
}

.cont-icons {
  width: 90px;
  display: inline-flex;
}

.cont-icons span {
  width: 40px;
  display: block;
  float: left;
  margin-right: 5px;
}

.distance {
  padding: 5px 10px;
  font-size: 16px;
}

.distance span {
  font-weight: 600;
  color: #000;
}

.app-header {
  border: none;
  // box-shadow: 0px 0px 22px #d8d5d5;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}

.nav-profile {
  width: 22px;
  height: auto !important;
}

.admin-main-heading h2 {
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0 23px;
}

.ptb {
  padding: 5em 0;
}

.card {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
}

.job-icons {
  width: 57px;
  height: 57px;
  position: absolute;
  left: 15px;
  top: -36px;
  border-radius: 5px;
  color: #fff !important;
}

.point {
  cursor: pointer;
}

.bg-dorange {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 152, 0, 0.4);
  background-color: #fb8f04;
}

.bg-dpink {
  background-color: #d81b60;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(233, 30, 99, 0.4);
}

.bg-dgreen {
  background-color: #43a047;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
}

.bg-dblue {
  background-color: #00acc1;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
}

.jobs-sec .card {
  min-height: 120px;
}

.text-value {
  padding-top: 10px;
  font-size: 32px;
  text-align: center;
  overflow-wrap: break-word;
  word-break: break-all;
}

.job-cont {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  color: #767676;
  overflow-wrap: break-word;
  white-space: nowrap;
}

.main-heading {
  font-size: 24px;
  font-weight: 600;
}

.sidebar .nav-link.active {
  background-color: #20a8d8;
}

.sidebar .nav-link.active .nav-icon {
  color: #fff;
}

.job-code {
  display: inline-block;
  width: calc(100% - 420px);
}

.job-code span {
  font-size: 24px;
  font-weight: 700;
  vertical-align: middle;
  color: #000;
  text-transform: uppercase;
}

.job-date {
  display: inline-block;
  width: 420px;
  float: right;
  text-align: right;
}

.job-date i {
  font-size: 20px;
  margin-right: 12px;
  vertical-align: middle;
}

.job-date span {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;

  display: inline-block;
  width: 49%;
}

.job-list-header {
  background-color: #dddddd;
  // padding: 0.8em 2em;
  padding: 20px 15px;
}

.job-content {
  padding: 0.5em 1em;
}

.job-location {
  display: inline-block;
  width: calc(100% - 200px);
}

.job-location i {
  font-size: 24px;
  vertical-align: middle;
}

.price-distance {
  display: inline-block;
}

.job-location ul li {
  list-style: none;
  padding: 8px 0;
}

.job-location span {
  font-size: 18px;
  margin-left: 20px;
  color: #515151;
}

// .job-location ul li:first-child:after
// {
//     content: "";
//     width: 1px;
//     height: 28px;
//     background: #b6b6b6;
//     position: absolute;
//     left: 61px;
//     top: 50%;
// }
.price-distance {
  text-align: right;
  float: right;
  margin-top: 10px;
}

.price-distance h5 {
  font-size: 26px;
  color: #23a9d8;
}

.w-90 {
  width: 90%;
}

.modal-footer {
  border-top: none;
}

.pay-due {
  color: red;
  font-size: 15px;
  font-weight: 600;
}

.delivery-type-box input[type="radio"]:checked + label,
input[type="radio"]:checked + label:hover {
  background-color: #46bf88;
  color: #fff;
  border-color: #46bf88;
}

.pay-ride-fare-btn {
  background-color: #4dbd74;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0;
}

.delivery-type-box input[type="radio"]:checked + label .u-deliv p {
  color: #fff;
}

.urgent-delivery {
  padding: 5px 5px !important;
  //  width: 47% !important;
  background: #fff;
  border-radius: 5px;
  min-height: 120px;
}

.udel-mar {
  margin-right: 5px;
}

.custom-radio-btns label {
  background: transparent !important;
  border: none !important;
  color: black !important;
  transition: none !important;
  width: 100% !important;
  text-align: left !important;
  padding-top: 0 !important;
}

.cust-yes-btn {
  display: inline-block;
  width: 100px;
}

.cust-no-btn {
  display: inline-block;
}

.custom-radio-btns
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #22a241;
  background-color: #22a241;
}

.edit-profile-form label {
  font-size: 15px;
  font-weight: 500;
  color: #424242;
}

.edit-profile-form input:focus {
  border: 1px solid #25a169;
}

.edit-profile-form {
  width: 80%;
}

.edit-profile-form input {
  border: 1px solid #ccc;
}

.profile-setting {
  width: 95%;
}

.upload-image-btn {
  color: #fff;
  width: 200px;
  font-size: 15px;
  padding: 10px 0;
  margin-top: 40px;
  border: 1px solid #fff;
}

.preview {
  margin-top: 80px;
}

.edit-inn-pro {
  border: none;
  width: 100%;
}

.bg-profile {
  background: #46bf88;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.change-pass input {
  border: 1px solid #ccc;
  height: 40px;
}

.change-pass label {
  font-size: 15px;
  font-weight: 500;
  color: #424242;
}

.lock-icon {
  text-align: center;
}

.lock-icon i {
  font-size: 55px;
  color: #46bf88;
}

.change-pass button {
  width: 100%;
  padding: 10px 0;
  font-size: 15px;
  font-weight: 500;
}

.checkbox-style-1 span {
  font-size: 13px !important;
  font-weight: 500;
  padding: 3px 20px !important;
  border-color: #ebebeb !important;
}

.change-pass-box {
  width: 45%;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid#46BF88 !important;
  background: #46bf88 !important;
  border-radius: 5px !important;
}

.change-pass-box form {
  padding: 15px 35px;
}

.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #46bf88;
  font-weight: 700;
}

.nav-tabs .nav-link {
  font-weight: 500;
  padding: 0;
  margin-right: 1rem;
}

.nav-tabs .nav-link:hover {
  border: none;
  border-bottom: 1px solid #46bf88;
}
.nav-tabs .nav-link.active:focus {
  border-bottom: 2px solid #46bf88;
}

.nav-tabs .nav-item {
  margin-right: 8px;
}

.job-detail-padding {
  padding: 20px 30px;
}

.job-dtl-header p {
  font-size: 15px;
  font-weight: 500;
  color: #767676;
}

.map-img {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.cust-img img {
  width: 50px;
  height: 50px;
}

.jd-pad {
  padding: 0 24px;
}

.total-fare,
.total-pay {
  color: #000 !important;
  font-size: 16px !important;
}

/*---- job-detail page----*/
.location {
  color: #000 !important;
  width: 86%;
  display: inline-block;
}

.order-summery-head h4 {
  font-size: 24px;
  font-weight: 500;
  margin-top: 0.5em;
  margin-bottom: 15px;
}

.order-summery-head p {
  font-size: 15px;
  font-weight: 500;
  // padding-left:20px;
  color: #747474;
}

.prescrip-image {
  padding: 0px 17px;
  margin-top: 2em;
  margin-bottom: 1em;
  display: inline-block;
}

.note {
  background-color: #f6f6f6;
  padding: 0 15px;
}

.location-icon {
  font-size: 23px;
  margin-right: 20px;
  vertical-align: top;
  margin-top: 4px;
  display: inline-block !important;
}

.jt-1 {
  display: inline-block;
  width: calc(100% - 200px);
  margin-top: 10px;
  font-size: 15px !important;
}

.jt-2 {
  display: inline-block;
  margin-top: 10px;
  font-size: 15px !important;
  font-weight: 700 !important;
  color: red !important;
}

.job-type {
  margin-top: 1.5em;
  background: #f8f8f8;
  padding: 13px 15px 0 15px;
}

.bck-btn button {
  width: 200px;
  padding: 10px 0;
  font-size: 20px;
}

.img-message {
  display: inline-block;
  width: 100%;
}

.pickup-box h3 {
  color: #4dbd74;
}

/*-----end job detail-------*/

.auto-cust input {
  min-height: 37px;
  background: #efefef;
  border: 1px solid #e4e7ea;
}

.auto-cust,
.auto-cust span,
.auto-cust input {
  width: 100%;
  display: inline-block;
}

.retry-se {
  cursor: pointer;
  text-align: center;
  display: block !important;
  margin: 0px auto;
  width: 155px;
  border-radius: 32px;
  background: #bbbb;
  padding: 10px;
  margin-bottom: 5px;
}

.retrt-cancel {
  cursor: pointer;
  background: #b70000;
  color: #fff !important;
  font-weight: 600;
}

/*-------------invoicesection--------*/
.payment-request h5 {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}

.payment-request p {
  font-size: 15px;
  margin: 0;
  padding: 0;
  color: #939393;
  font-weight: 500;
}

.mtt-20 {
  margin-top: -20px;
}

.invoice-setting {
  width: calc(100% - 250px);
}

.phar-1 {
  display: inline-block;
  width: calc(100% - 200px);
  margin-top: 10px;
  font-size: 15px !important;
}

.phar-2 {
  display: inline-block;
  margin-top: 10px;
  font-size: 15px !important;
  font-weight: 700 !important;
  color: red !important;
  text-align: right;
  float: right;
}

.pay-amt {
  font-size: 16px !important;
}

.pay-cont {
  font-size: 18px !important;
  color: #4dbd74 !important;
}

.job-type {
  margin-top: 1.5em;
  background: #f8f8f8;
  padding: 13px 15px 0 15px;
}

.p-done {
  font-size: 16px !important;
  margin-bottom: 6px !important;
}

.bg-trans {
  background: transparent;
  margin-top: 1.2em;
}

.set-yes {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

/*----------end invoice--------*/

/*-----transaction page------*/
.trans-date {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

.trans-code,
.pharma-name {
  font-size: 15px;
  margin-bottom: 0;
  color: #959595;
}

.trans-padding {
  padding: 10px 25px;
}

.trans-amt {
  font-size: 22px;
  font-weight: 600;
  color: #20a8d8;
}

.trans-amt span {
  font-size: 15px;
  font-weight: 500;
}

.sort-txt,
.sort-selection {
  display: inline-block;
}

.sort-txt {
  font-size: 15px;
  font-weight: 500;
  color: #a6a6a6;
  margin-right: 10px;
}

.sort-selection select {
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #4dbd74;
  height: 45px;
}

.sort-selection {
  width: 75%;
}

.start-end-date {
  width: 100%;
}

.start-end-date button {
  text-align: left;
  height: 45px;
  font-size: 15px;
  font-weight: 500;
}

.start-end-date .dropdown-toggle::after {
  display: none;
}

.arrown-d {
  float: right;
  margin-top: 5px;
}

.start-end-date .dropdown-menu {
  width: 340px;
}

.ddrop-padding {
  padding: 5px 18px;
}

.start-end-date label {
  font-size: 15px;
  font-weight: 600;
}

.start-end-date .dropdown-item {
  font-weight: 500;
}

.start-end-date .menuli {
  margin-top: -15px !important;
}

.start-end-date ul li {
  text-align: center;
}

.start-end-date input {
  height: 45px;
}

.start-end-date button {
  border-radius: 5px !important;
}

.start-end-date ul li a:hover {
  background-color: #4dbd74;
}

.search-box {
  border: none;
  outline: none !important;
  box-shadow: none;
  height: 45px;
}

.search-box:focus {
  outline: none !important;
  box-shadow: none;
}

.search-trans-box .input-group-text {
  background: transparent;
  border: none;
}

.cancel-btn {
  color: #da4444;
  padding: 6px 10px;
  width: 165px;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  border: 2px solid #da4444;
  border-radius: 5px;
  background: #fff;
  text-transform: capitalize;
}

.assign-d-name {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
}

/*-----end transaction page-----*/

/*-------create job edit delivery----*/
.edit-delivery {
  position: absolute;
  top: 20px;
  right: -20px;
  width: 20px;
  font-size: 18px;
  background: transparent;
  border: none;
  outline: none !important;
}

.edit-delivery1 {
  position: absolute;
  top: 105px;
  right: -20px;
  width: 20px;
  font-size: 18px;
  background: transparent;
  border: none;
  outline: none !important;
}

/*--------end create create job edit delivery--------*/

.unavail .price,
.dollar {
  color: grey !important;
  font-size: 22px;
  font-weight: 600 !important;
  /* white-space: nowrap; */
}

/*------manage card changes css--------*/
.manag-card-label {
  color: #fff !important;
}

.saved-card-check {
  border: 2px solid #fff !important;
}

.saved-card input[type="radio"]:checked ~ .check::before {
  background-color: #fff !important;
}

.saved-card ul li {
  margin-top: 10px;
  border: 1px solid #fff;
}

.payment-summery span {
  margin-left: 0px !important;
}

/*-------end manag card changes css----*/

/*--------notification------------*/

.dropdown-hover {
  position: relative;
  display: inline-block;
}

.dropdown-content-hover {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0px 0px;
  z-index: 1;
  right: -25px;
  border-radius: 5px;
  text-align: left;
}

.dropdown-hover:hover .dropdown-content-hover {
  display: block;
}

.bell-notification {
  background-color: #edf1f6;
  border-radius: 7px;
  padding: 0.1rem;
}

.notification-list {
  padding-left: 0;
}

.notification-list li {
  list-style: none;
  padding: 12px 30px;
  line-height: 1.6;
  border-bottom: 1px solid #edf1f6;
}

.notification-list li:hover {
  background-color: #e3e3e3;
}

.notification-list li a {
  text-decoration: none;
  font-size: 14px;
  color: #686868;
  font-weight: 500;
}

.notification-list li h5 {
  font-size: 14px;
  margin-bottom: 0px;
  color: #000;
  font-weight: 600;
}

.notification-list li p {
  font-size: 12px;
  line-height: 1.3;
  margin-top: 4px;
  color: #2a2f3b;
  margin-bottom: 3px;
}

.notification-header a {
  color: #fff !important;
  font-size: 16px !important;
}

.notification-list img {
  width: 35px;
  border-radius: 100%;
  height: 35px;
}

.all-notifications {
  height: 100%;
  overflow-y: scroll;
  max-height: 300px;
}

.drop-margin {
  margin: 0 15px;
  box-shadow: none;
}

.drop-margin:focus {
  box-shadow: none !important;
  outline: none !important;
  border: transparent;
}

/*---------end notification-----------*/
.customer-info {
  width: 100%;
}

.customer-info td {
  max-width: 190px !important;
  vertical-align: top;
  /* width: 100%; */
  padding-right: 10px;
  padding-bottom: 11px;
  overflow-wrap: break-word;
  word-break: break-all;
}

/*---------login page-------*/

.login-box label {
  font-size: 15px;
  font-weight: 500;
}

.login-box input,
.login-box button {
  width: 100%;
  height: 45px;
}

.login-box p {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
}

.login-box a {
  font-size: 15px;
  font-weight: 500;
}

.body-bg {
  background-color: #4dbd74;
}

/*--------end login------*/

/*---------forgot password-------*/
.main-heading-forgot {
  text-align: center;
}

.forgot-password p {
  font-size: 15px;
  font-weight: 500;
  color: #787878;
}

.logo-box {
  text-align: center;
  margin-bottom: 25px;
}

.forgot-password {
  padding: 55px 70px;
  margin-bottom: 100px;
}

.forgot-password label {
  font-size: 13px;
  font-weight: 500;
}

.forgot-password input,
.forgot-password button {
  width: 100%;
  height: 45px;
}

.mt-09 {
  margin: 9em 0;
}

.body-bg {
  background-color: #4dbd74;
}

/*---------end forgot password----*/

/*-------register-------*/
.register-box label {
  font-size: 15px;
  font-weight: 500;
}

.register-box input,
.register-box button {
  width: 100%;
  height: 45px;
}

.register-box p {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
}

.register-box a {
  font-size: 15px;
  font-weight: 500;
}

/*------end register---*/

.logo-box img {
  width: 150px;
  height: auto;
}

.text-red {
  color: #ec2d2c !important;
}

.remove-input input {
  border: none;
  outline: none !important;
  box-shadow: none !important;
  background: transparent;
  top: -7px;
}

.remove-input input:focus {
  background: transparent !important;
}

.intl-tel-input {
  width: 100%;
}

.country-field {
  height: 45px;
}

.intl-tel-input.separate-dial-code .selected-flag {
  background-color: transparent !important;
  top: -7px;
}

/*---------invoice detail--------*/
.invoive-detail-header {
  background-color: #4dbd74;
  padding: 13px 5px;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  margin: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.r-width {
  width: 100%;
  margin: auto;
}

.invoice-dtl-mail {
  font-size: 15px;
  font-weight: 500;
  color: #878787;
}

.invoice-dtl-cutomer-info {
  font-size: 15px;
  font-weight: 500;
  color: #878787;
}

.map-marker-icon {
  font-size: 20px;
  vertical-align: middle;
  margin-top: 3px;
  margin-right: 10px;
  color: #4dbd74;
}

.pharmacy-pay {
  font-size: 15px;
  font-weight: 500;
  color: #878787;
}

.invoice-detail-table {
  display: inline-table;
}

.phar-payment {
  font-size: 16px;
  font-weight: 500;
  color: #fc4948;
  margin-top: 2px;
}

.date-filter-list {
  left: 48px !important;
  right: 0;
  top: 100%;
  transform: translateY(0px);
  bottom: auto;
  margin: auto;
}

.sort-cont {
  align-self: center;
  font-size: 14px;
  font-weight: 500;
}

.pay-sort-cont {
  font-size: 14px;
  font-weight: 500;
}

.h-45 {
  height: 43px;
}

.select-sort {
  border: 1px solid #edf1f6;
  border-radius: 7px;
}

.download-pdf-btn {
  height: 45px;
  font-size: 14px;
  font-weight: 500;
}

.back-btn-icon {
  font-size: 21px;
  color: #3a9d5d;
}

.date-sort-box {
  border: 1px solid #4dbd74;
  background-color: transparent;
  color: #6c6b6b;
  font-weight: 400 !important;
}

.date-sort-box:hover {
  border: 1px solid #4dbd74;
  background-color: transparent;
  color: #6c6b6b;
  font-weight: 400;
}

.date-sort-box:focus {
  border: 1px solid #4dbd74;
  background-color: transparent !important;
  color: #6c6b6b !important;
  font-weight: 400;
}

.paid-status-icon i {
  font-size: 38px !important;
  color: #4dbd74;
}

.pending-status-icon i {
  font-size: 38px !important;
  color: #e6d035;
}

.process-status-icon i {
  font-size: 38px !important;
  color: red;
}

.driver-transaction-amt {
  font-size: 28px;
  font-weight: 500;
}

.dest {
  width: 43%;
}

.phara-transaction-list {
  margin-bottom: 15px !important;
  border-bottom: 1px solid #ccc;
}

.r-width {
  width: 100%;
  margin: auto;
}

.total-earn {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
}

.pay-btn {
  background-color: #fff;
  font-size: 15px;
  font-weight: 500;
  height: 45px;
}

.phar-pay-btn {
  background-color: #4dbd74 !important;
}

.pay-received {
  font-weight: 600;
}

.invoice-detail-table .thead-dark th {
  background-color: #4dbd74 !important;
  border-color: #4dbd74 !important;
}

.cheque {
  color: deepskyblue;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

.code-span {
  font-size: 18px !important;
  text-transform: none !important;
}

// for tags start
.checkbox-label {
  color: #000;
}

clr-checkbox ::ng-deep input[type="checkbox"]:checked + label::before {
  background-color: currentColor;
}

// for tags end

agm-map {
  height: 300px;
}

/*-------start notification css------*/
.remove-after::after {
  content: "";
  border: none !important;
}

.remove-after {
  background: transparent;
  border: none;
  height: auto;
  padding: 0;
}

.notification {
  color: #2a2f3b;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border-radius: 7px;
  background-color: #edf1f6;
  padding: 6px 8px;
}

.notification .badge {
  position: absolute;
  top: -8px;
  right: -10px;
  padding: 3px 6px;
  border-radius: 50%;
  background-color: red;
  color: white;
}

.notification-list {
  left: -322px !important;

  right: 0 !important;
}

/*-------end notification css---------*/

.cust-sign {
  max-height: 170px;
  overflow: hidden;
  margin: auto;
  width: 135px;
}

.cust-sign img {
  width: 130px !important;
}

.search-top-input {
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  background: none;
}

.srcbtnn1 .btn {
  height: 37px;
  margin-top: 17px;
}

.btnclass {
  margin-left: 5px;
}

// #map_canvas{
//  width: 300px;
//     height: 300px;
// }
#mapCanvas {
  height: 300px;
}

.customer-information-radio-btn {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 2px;
  margin-bottom: 2px;
}

.customer-information-radio-btn li {
  display: inline-block;
  list-style: none;
  padding: 0 15px;
}

body {
  font-family: "Montserrat", sans-serif;
  background: #eee;
  font-size: 12px;
}

@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    width: 225px;
  }

  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
  html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 225px;
  }
}

input[type="radio"]:checked + label,
input[type="radio"]:checked + label:hover {
  background-color: #2a2f3b;
  color: #fff;
  border-color: #2a2f3b;
}

.nav-tabs .nav-item.active.delivery,
.nav-tabs .delivery .nav-link.active,
tab.delivery.tab-pane.active {
  background: #c4dcff;
  display: inline-block;
}

input[type="radio"] + label {
  background-color: #fff;
}

.nav-tabs .nav-item.active.Pickup,
.nav-tabs .Pickup .nav-link.active,
tab.Pickup.tab-pane.active {
  background: #fff9d5;
  display: inline-block;
}

.pac-container {
  z-index: 1051 !important;
}

// .customer-info td
// {max-width: 200px !important;
//    vertical-align: top;
//     width: 42%;
//     padding-bottom: 11px;
// }

.card-mng input[type="radio"] + label {
  background-color: transparent;
}

.pendingDate {
  float: right;
  margin-top: -20px;
}

// .nav-tabs .pickk-up-tab.active {
//     border: 1px solid #fdea07;
//     color: #fff;
//     background-color: #ffc107;
// }

.card-pricing {
  border: 3px solid white;
  cursor: pointer;
}

.card-pricing:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border: 3px solid #4dbd74;
  cursor: pointer;
}

.card-pricing .list-unstyled li {
  padding: 0.5rem 0;
  color: #6c757d;
}

.card-pricing:hover .h-activ {
  background-color: #4dbd74 !important;
  cursor: pointer;
}

.unavail {
  background-color: #e6e6e6;
  color: grey !important;
}

.unavail .plan-name {
  background-color: #afafaf !important;
}

.unavail .price,
.dollar {
  color: grey !important;
}

.unavail .planBtn {
  background-color: grey !important;
  border: 1px solid grey;
}

.unavail:hover {
  background-color: #e6e6e6;
  color: grey !important;
  cursor: block;
  border: none !important;
}

.dd-img-set {
  width: 100%;

  max-height: 285px;
  overflow: hidden;
  border-radius: 10px;
}

.driver-d-img {
  width: 100%;
  border-radius: 10px;
}

.d-driver-name {
  font-size: 24px;
  text-transform: capitalize;
  margin-bottom: 0;
  font-weight: 600;
}

.dd-mail {
  font-size: 16px;
  color: #858585;
  margin-bottom: 0;
}

.vehicleImg {
  width: 145px;
  height: auto;
  overflow: hidden;
}

.commt-list {
  padding: 10px;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: 5px auto;
}

.commt-list li {
  list-style-type: none;
  margin: 10px;
  background-color: #e2e2e2f2;
  border-radius: 20px;
  padding: 10px;
  height: auto;
}

.commt-list li p {
  text-overflow: ellipsis;
  max-height: 210px;
  overflow: hidden;
}

.cmtmore {
  float: right;
  margin-right: 10px;
}

.comntPopBdy {
  max-height: 400px;
  overflow: auto;
}

.datetime {
  font-size: 12px;
  float: right;
  color: green;
  margin-top: -12px;
}

.info-dt {
  color: #424242 !important;
  font-weight: 400 !important;
}

// style for edit client

.morIcon {
  font-size: 17px;
  background: #4dbd74;
  padding: 3px;
  border-radius: 50%;
  padding: 3px 10px;
  color: white;
}

.mob-create-job {
  display: none;
}

.mob-language {
  display: none;
}

// style for edit client end

.mob-table-data {
  display: none;
}

// responsive css//

@media only screen and (max-width: 800px) {
  .sidebar .nav-link {
    width: 88% !important;
  }

  .create-jobs {
    display: none;
  }

  .mob-create-job {
    display: block;
  }

  //    .navbar-toggler{display: none !important;}

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;
  }

  table th,
  table td {
    padding: 0.625em;
    text-align: center;
  }

  table th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}

.mob-tb-heading {
  display: none;
}

.media-body {
  flex: 1;
  max-width: 300px;
}

@media only screen and (max-width: 600px) {
  .tab-content {
    margin-top: -12px;
  }

  .dateVx {
    width: 100% !important;
  }

  .media-body {
    flex: 1;
    max-width: auto;
  }

  .mob-tb-heading {
    display: block;
  }

  .sn-pdf {
    margin-top: -10px;
  }

  .u-deliv h2 {
    font-size: 13px;
  }

  .est-pric {
    width: 100%;
  }

  .nav-profile {
    width: 40px;
  }

  .req-cab {
    padding: 5px 20px !important;
  }

  .w100-main {
    width: 100% !important;
  }

  .destination,
  .add-pharmacy {
    width: 100%;
  }

  .change-pass-box {
    width: 100%;
  }

  .bg-profile {
    width: 93%;
    margin: 0 auto;
  }

  .phar-pay-btn {
    width: 100%;
    margin: 15px auto;
    font-size: 12px;
  }

  .download-pdf-btn {
    width: 100%;
  }

  .srcbtnn button {
    width: 100%;
    margin-bottom: 40px;
  }

  .payment-box {
    margin-top: 2rem !important;
  }

  .pay-padding {
    padding: 10px 10px !important;
  }

  .mob-table-data {
    display: block;
  }

  .c-info {
    display: inline-block;
    width: 100%;
    font-size: 12px;
  }

  .add-pharmac {
    width: 100%;
  }

  .slect-language select {
    width: 40px;
    float: right;
    margin-right: -50px;
    letter-spacing: 2px;
  }

  .mob-language {
    display: block;
  }

  .app-header .navbar-nav .dropdown-menu-right {
    right: -30px !important;
  }

  .notification {
    margin-right: -25px;
  }

  .app-header .nav-item {
    position: relative;
    min-width: 50px;
    right: -50px;
    margin: 0;
    text-align: right;
  }

  .app-header .navbar-brand {
    width: 130px !important;
  }

  .app-header .navbar-brand img {
    width: 55% !important;
    height: 20px;
  }

  .notification span i {
    font-size: 12px !important;
  }

  .mob-create-job {
    display: block;
    left: 65px;
    /* float: left; */
    position: absolute;
  }

  #ad-ph-body,
  #cj-ph-2 {
    padding: 5px !important;
  }

  .u-deliv p {
    width: 150px !important;
  }

  .c-price {
    padding-top: 5px;
    font-size: 20px;
    float: right;
    margin-right: 30px;
  }

  .cancel-btn,
  .req-cab {
    font-size: 12px !important;
  }

  .edit-delivery {
    position: absolute;
    top: 40px;
    right: 10px;
  }

  .edit-delivery1 {
    position: absolute;
    top: 135px;
    right: 10px;
    width: 20px;
    z-index: 1;
  }

  .main .container-fluid {
    padding: 0 10px !important;
  }

  .c-j-card {
    padding: 0 !important;
  }

  .notification-list {
    left: -168px !important;
    width: 300px;
    /* right: 100px !important; */
  }

  .job-code {
    display: inline-block;
    width: 100%;
  }

  .job-code span {
    font-size: 14px;
    font-weight: 700;
    vertical-align: middle;
    color: #000;
    text-transform: uppercase;
  }

  .job-list-header {
    background-color: #dddddd;
    padding: 20px 15px;
    height: 138px;
  }

  .job-date span {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
    width: 50%;
  }

  .code-span {
    font-size: 15px !important;
  }

  .job-code {
    display: inline-block;
    width: 100%;
    margin: 0px 0px 30px 0px;
  }

  .nav-tabs .nav-item {
    margin-right: 0px;
    margin: 10px 0px 10px 4px;
    width: 48%;
  }

  .srcbtnn1 .btn {
    height: 37px;
    width: 100%;
    margin-top: 17px;
  }

  .job-location ul {
    padding-left: 0;
  }

  .job-location {
    display: inline-block;
    width: 70%;
    font-size: 14px;
  }

  .price-distance h5 {
    font-size: 16px;
  }

  .invoice-detail-table {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .c-j-label {
    font-size: 10px;
  }

  .c-j-col {
    padding: 5px !important;
  }
}

.mob-tb-card {
  -webkit-box-shadow: -1px 0px 15px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: -1px 0px 15px 0px rgba(0, 0, 0, 0.18);
  box-shadow: -1px 0px 15px 0px rgba(0, 0, 0, 0.18);
  margin: 10px auto;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .dest {
    width: 100%;
  }

  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
    content: attr(data-label);
    float: left;
    padding-right: 40px;
    font-weight: bold;
    color: rgb(6, 172, 6);
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

// ======end responsive css//
.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

.res-radio {
  width: 30%;
}

@media (max-width: 770px) {
  .res-radio {
    width: 100%;
  }
}

.pop-job-list {
  padding-left: 0;
  max-height: 400px;
  overflow: auto;
}

.pop-job-list li {
  background-color: rgba(227, 227, 227, 0.925);
  padding: 10px;
  border-bottom: 1px solid darkgray;
  list-style-type: none;
}

.pop-list-label {
  font-weight: 600;
  font-size: 12px;
  float: left;
}

.pop-list-value {
  font-size: 12px;
  float: right;
}

.pop-list-btn {
  font-size: 14px;
  margin-top: -30px;
  color: white;
  background-color: rgb(249, 56, 56);
  border-radius: 25px;
  padding: 5px 10px;
  float: right;
}

.pop-list-btn:hover {
  color: white;
  cursor: pointer;
}

.pop-job-list li p {
  font-size: 15px;
  font-weight: 600;
}

.custom-radio-width input[type="radio"] + label {
  width: unset;
  border-radius: 7px;
  font-size: 12px;
  padding: 6px;
  border: 1px solid #edf1f6;
  background-color: #fafbfd;
}

.custom-radio-width input[type="radio"]:checked + label {
  color: #fff !important;
  background-color: #2a2f3b;
}

// step view css start

.order-track {
  display: inline-block;
  width: 100%;
  margin-top: 50px;
}

.tracking {
  width: 20%;
  float: left;
  position: relative;
}

.track-succ .border-line {
  border-color: #b7b7b7;
}

.border-line {
  width: 100%;
  /*border-top: 1px solid #b8b8b8;*/
  border-bottom: 2px solid #b8b8b8;
  height: 5px;
  margin: 15px 0px;
  position: absolute;
}

.track-succ.tracking span {
  height: 37px;
  width: 37px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: green;
  border-radius: 100%;
  text-align: center;
  line-height: 2.5;
  color: #fff;
}

.tracking p {
  margin: 0px;
  font-size: 13px !important;
  font-weight: 600;
}

.m-top {
  margin-top: 45px !important;
}

.track-disable span {
  height: 37px;
  width: 37px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #d5d5d5;
  border-radius: 100%;
  text-align: center;
  line-height: 2.5;
  color: #fff;
}

.resend-btn a {
  color: #007ad9 !important;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.privacy-policies a {
  color: #4dbd74 !important;
  // text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.privacy-policies {
  text-align: center;
}

.subs-heading {
  background-color: #607da9;
  font-size: 13px;
  display: block;
  float: left;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

// step view css end

// cookie content banner css start
.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2147483645;
  box-sizing: border-box;
  width: 100%;

  background-color: #f1f6f4;
}

.cookie-consent-banner__inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0;
}

.cookie-consent-banner__copy {
  margin-bottom: 16px;
}

.cookie-consent-banner__actions {
}

.cookie-consent-banner__header {
  margin-bottom: 8px;

  font-family: "CeraPRO-Bold", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__description {
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  color: #838f93;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  min-width: 164px;
  padding: 11px 13px;

  border-radius: 2px;

  background-color: #2ce080;

  color: #fff;
  text-decoration: none;
  text-align: center;
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.cookie-consent-banner__cta--secondary {
  padding: 9px 13px;

  border: 2px solid #3a4649;

  background-color: transparent;

  color: #2ce080;
}

.cookie-consent-banner__cta:hover {
  background-color: #20ba68;
}

.cookie-consent-banner__cta--secondary:hover {
  border-color: #838f93;

  background-color: transparent;

  color: #22c870;
}

.cookie-consent-banner__cta:last-child {
  margin-left: 16px;
}

.cookie-banner {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262a2e;
  color: #fff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  z-index: 9991;
  text-align: center;
}

.cookie-banner p {
  margin: 0px;
  font-size: 17px;
}

.close {
  height: 20px;
  background-color: #777;
  border: none;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}

.edit-mail-cont {
  position: absolute;
  right: 0;
  top: 22px;
  cursor: pointer;
}

.edit-mail-cont1 {
  position: absolute;
  right: 0;
  top: 6px;
  cursor: pointer;
}

.verification-flag-box .intl-tel-input.separate-dial-code .selected-flag {
  top: -3px !important;
}

.input-phone input {
  padding-top: 15px;
}

//  cookie content banner end

// .item-price-charge > input:checked ~ .box, .item-price-charge.checked > .box{
//     background-color: #22a241 !important;

// }

.item-price-charge > .box {
  border-radius: 5px !important;
}

.my-custom-dropdown .multiselect-dropdown .dropdown-btn {
  width: 100% !important;
}

.add-edit-clientmodel {
  width: 100% !important;
}

.icon-job {
  width: 25px;
}

.padding-all {
  padding: 20px 25px;
}

.animated-inputs input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label {
  top: -12px;
  left: 28px;
  font-size: 13px;
  opacity: 1;
  background: #fff;
  color: #4f3aee;
  font-weight: 600;
}

.animated-inputs input:focus {
  border: 2px solid #4f3aee !important;
}

.animated-inputs .inputText {
  font-size: 13px;
  width: 100%;
  height: 40px;
}

.animated-inputs .floating-label {
  position: absolute;
  pointer-events: none;
  left: 28px;
  top: 9px;
  transition: 0.2s ease all;
  font-size: 13px;
  text-transform: capitalize;
  color: #5c6873;
}

.animated-pd {
  padding: 0px 15px;
}

.change-acct-btn {
  background: #707070;
  color: #fff !important;
  font-size: 13px;
  font-weight: 500;
}

.cc-price {
  font-size: 24px !important;
}

.my-custom-label label {
  background: transparent !important;
  color: #000 !important;
  border: none !important;
  width: auto !important;
  padding: 0 !important;
  color: #5c5c5c !important;
}

.job-for-list {
  padding-left: 0;
}

.job-for-list li {
  list-style: none;
  display: inline-block;
  width: 45%;
}

.my-custom-label .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #46bf88;
  background-color: #46bf88;
}

.my-custom-label .custom-control-label::before {
  border: #767676 solid 2px;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
  border-top: 5px solid #adadad !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
}

.my-custom-dropdown .multiselect-dropdown .dropdown-btn {
  border: 1px solid #ebebeb !important;
}

.d1-name {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  color: #444444;
}

.dd1-name {
  margin-bottom: 0;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  color: #8b8a8a;
}

.private-click-box {
  border: 2px solid #e7e7e7;
  padding: 15px 10px;
  border-radius: 5px;
}

.p-select {
  font-size: 11px;
  font-weight: 500;
  color: #8b8a8a;
}

.custom-checkbox > .box {
  border-radius: 5px !important;
}

#creat-j .nav-link {
  color: #6a6969;
  font-weight: 700 !important;
}

.delivery-type-box input[type="radio"]:checked + label .truck-icon {
  filter: brightness(10) !important;
}

@media screen and (max-width: 768px) {
  .change-acct-btn {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 576px) {
  .my-custom-label {
    margin-top: 15px;
  }

  .job-for-list li {
    width: 25%;
  }

  .my-m-footer {
    display: inline-block;
    margin: inherit !important;
  }

  .cancel-btn,
  .req-cab {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 415px) {
  .custom-radio-width input[type="radio"] + label {
    width: 49%;
  }
}

.auto-cust input {
  min-height: 37px;
  background: #fff !important;
  border: 1px solid #e4e7ea;
}

.checkbox-style-1 {
  font-size: 12px;
  font-weight: 500;
  border-color: #ccc;
  height: 38px;
  line-height: 2.2;
}

.pencil-button {
  position: absolute;
  bottom: 54px;
  left: 170px;
}

.pencil-button1 {
  position: absolute;
  bottom: 40px;
  right: 24px;
}

.job-for-list input[type="radio"]:checked + label {
  color: #707070 !important;
  font-weight: 500;
}

.job-for-list input[type="radio"] + label {
  color: #afabab !important;
  font-weight: 400;
  font-size: 13px;
}

.customer-information-radio-btn input[type="radio"]:checked + label {
  color: #707070 !important;
  font-weight: 500;
}

.customer-information-radio-btn input[type="radio"] + label {
  color: #afabab !important;
  font-weight: 400;
  font-size: 13px;
}

.d-type input[type="radio"] + label {
  color: #707070;
}

@media screen and (max-width: 1281px) {
  .pencil-button {
    bottom: 55px;
  }
}

@media screen and (max-width: 1252px) {
  .pencil-button {
    bottom: 83px;
  }
}

@media screen and (max-width: 1252px) {
  .pencil-button {
    bottom: 64px;
  }
}

@media screen and (max-width: 1230px) {
  .pencil-button1 {
    right: 20px;
  }
}

@media screen and (max-width: 1210px) {
  .pencil-button {
    bottom: 45px;
  }
}

@media screen and (max-width: 1200px) {
  .pencil-button {
    bottom: 50px;
    left: 300px;
  }

  .pencil-button1 {
    right: 43px;
    top: 69px;
  }
}

@media screen and (max-width: 1070px) {
  .pencil-button {
    bottom: 50px;
    left: 260px;
  }
}

@media screen and (max-width: 767px) {
  .pencil-button {
    top: 70px;
    left: 540px;
  }

  .pencil-button1 {
    right: 43px;
    top: 190px !important;
  }

  .urgent-delivery {
    width: 100% !important;
  }
}

@media screen and (max-width: 725px) {
  .pencil-button {
    top: 70px;
    left: 490px;
  }
}

@media screen and (max-width: 690px) {
  .pencil-button {
    top: 70px;
    left: 460px;
  }
}

@media screen and (max-width: 650px) {
  .pencil-button {
    top: 70px;
    left: 400px;
  }
}

@media screen and (max-width: 540px) {
  .pencil-button {
    left: 360px;
  }
}

@media screen and (max-width: 490px) {
  .pencil-button {
    left: 310px;
  }
}

@media screen and (max-width: 440px) {
  .pencil-button {
    left: 270px;
  }
}

@media screen and (max-width: 400px) {
  .pencil-button {
    left: 210px;
  }
}

.iti__flag-container {
  top: -15px !important;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent !important;
}

.collection-modal-list {
  padding-left: 0;
}

.collection-modal-list li {
  list-style: none;
  display: inline-block;
  padding-right: 5px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-top: 15px;
}

.btn-up {
  border: 1px solid #000;
  color: #000;
  background-color: white;
  padding: 4px 20px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 500;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-btn-wrapper label {
  font-size: 16px;

  font-weight: 500;
}

.import-jobs {
  background-color: #46bf88;
  color: #fff !important;
  font-size: 15px;
  font-weight: 500;
  width: auto;
  margin-top: 20px;
}

.cancel-jobs {
  border: 1px solid #ccc;
  background: transparent;
  color: #888787 !important;
  margin-top: 20px;
}

.w-day {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 8px;
}

.w-day li {
  list-style: none;
  display: inline-block;
  line-height: 1.5;
  font-weight: 600;
  padding-right: 7px;
}

.download-btn {
  position: absolute;
  right: 0;
  top: 0;
  color: #4dbd74 !important;
}

.scroolTable {
  max-height: 400px;
  overflow: auto;
}

.my-custom-label label {
  text-align: left !important;
}

.server-approved-img {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 18px;
}

.server-approved-img img {
  width: 100% !important;
  border-radius: 0px !important;
}

.qr-btn {
  width: auto;
  height: 26px;
  line-height: 1;
  font-size: 13px;
  font-weight: 300;
  margin-left: 20px;
}

.qr-btn i {
  font-size: 12px;
  margin-right: 4px;
}

.nmbr-box-list {
  padding: 0px;
}

.nmbr-box-list li {
  list-style: none;
  display: inline-block;
}

.nmbr-box-list li input {
  width: 75px;
  border-radius: 5px;
  text-align: center;
}

.nmbr-box-list li p {
  width: auto;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
}

.filecstm-btn .fileUpload p {
  margin-bottom: 0;
  background: #2a2f3b;
  width: auto;
  display: inline-block;
  padding: 5px 12px;
  font-weight: 500;
  border-radius: 4px;
  color: #fff;
}

.close-btn {
  background-color: red;
  color: #fff;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 5px;
  border: none;
  margin-left: 8px;
}

.filecstm-btn {
  margin-top: 0px;
  width: 100%;
}

.btn-nd-up {
  display: inline-flex;
  width: 100%;
  margin-top: 15px;
}

.calendar-chevron {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 0px;
  display: flex;
  cursor: pointer;
}

.calendar-chevron-rigth {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 0px;
  display: flex;
  cursor: pointer;
}

.no-boder {
  border: none !important;
}

.text-align-left {
  text-align: left;
  font-size: 18px;
}

.bg-griss {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}
.headerTh {
  font-size: 14px !important;
}
.not-shadow {
  box-shadow: none !important;
}
.boder-table {
  border-bottom: 1px solid #c8c8c8 !important;
}

.gap-1 {
  gap: 1rem;
}
.text-bg-success {
  color: #fff;
  background-color: $green;
}
.text-bg-warning {
  color: #fff;
  background-color: $yellow;
}
.text-bg-danger {
  color: #fff;
  background-color: $red;
}
